import React, {useState} from 'react'
import Section from '../components/section'

const buttonStyles = {
  backgroundColor: 'white',
  color: 'black',
  border: '2px solid black',
  borderRadius: '25px',
  padding: '10px 55px',
  boxShadow: '3px 4px 0px rgba(0, 0, 0, 1)',
  cursor: 'pointer',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  transition: 'transform 0.2s',
}

const SubscribeForm = ({title, listIds}) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    setError('')

    const data = {
      email,
      listIds, // Using listIds prop here
    }

    try {
      const response = await fetch('https://api.brevo.com/v3/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key':
            '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',
        },
        body: JSON.stringify(data),
      })

      if (response.ok) {
        setSuccess(true)
        setEmail('')
      } else {
        const errorData = await response.json()
        setError(
          errorData.message ||
            'Error al suscribirse. Por favor, intenta de nuevo más tarde.',
        )
      }
    } catch (err) {
      setError('Error al suscribirse. Por favor, intenta de nuevo más tarde.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Section display={'flex'}>
      <div className="container">
        <div className="columns is-vcentered">
          <div
            className="column is-half has-text-centered"
            style={{padding: 35}}
          >
            <h2 className="title is-3">
              {title} {/* Using title prop here */}
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Tu correo electrónico"
                    required
                  />
                </div>
              </div>
              <div className="control has-text-centered">
                <button type="submit" disabled={loading} style={buttonStyles}>
                  {loading ? 'Suscribiendo...' : 'Suscribir'}
                </button>
              </div>
            </form>

            {/* Success Notification */}
            {success && (
              <div className="notification is-success">
                ¡Gracias por suscribirte!
              </div>
            )}

            {/* Error Notification */}
            {error && <div className="notification is-danger">{error}</div>}
          </div>
          <div className="column is-half">
            <figure className="image is-4by3">
              <img src="/assets/images/mailgirl.svg" alt="Placeholder" />
            </figure>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SubscribeForm
